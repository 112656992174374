import React, { useEffect, useMemo, useRef, useState } from "react";

import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";

import consoleConfig from "console_config";
import HeaderMenuMobile from "Containers/NavBar/HeaderMenu/mobile";
import { AddButton } from "ds/Button";
import LoadingIcon from "ds/Icons/Loading";
import Tick from "ds/Icons/Tick";
import { Label } from "ds/Typography";
import useUrls from "Hooks/useUrls";
import {
  selectFeatureCreateOrganizationEnabled,
  selectFeatureDisableAllProjectsEnabled
} from "Reducers/featureFlags/featureFlags.selectors";
import { organizationsMultiVendorOrgsSelector } from "Reducers/organization";
import { loadingListSelector } from "Reducers/organization/subscription";
import { useAppSelector } from "Store/hooks";

import * as S from "./styles";

import type { Organization } from "platformsh-client";

const NUMBER_OF_ORGS_BEFORE_OVERFLOW = 6;
const NUMBER_OF_ORGS_BEFORE_OVERFLOW_ALL_PROJECTS = 5;

type FormattedOrganizationsProps = {
  organizationsList?: Organization[];
  searchInputValue?: string;
};

type OrganizationListItemProps = {
  isActive: boolean;
  onClick: () => void;
  label: string;
  vendor: string;
  showVendor: boolean;
};

const getFormattedOrganizations = ({
  organizationsList,
  searchInputValue
}: FormattedOrganizationsProps) => {
  return searchInputValue
    ? organizationsList?.filter(organization =>
        organization?.label
          .toLowerCase()
          .trim()
          .includes(searchInputValue?.toLocaleLowerCase().trim())
      )
    : organizationsList;
};

const OrganizationListItem = ({
  isActive,
  onClick,
  label,
  vendor,
  showVendor
}: OrganizationListItemProps) => {
  const theme = useTheme();
  const isLoadingOrganizationSubscriptions =
    useAppSelector(loadingListSelector);

  return (
    <S.ListItem isActive={isActive} onClick={onClick}>
      {consoleConfig.CUSTOM_ORGANIZATION_VENDORS && showVendor ? (
        <S.OrganizationListItemContent>
          <S.ListItemIcon
            src={`/logo-${vendor}${theme.name === "dark" ? "-dark" : ""}.svg`}
          />
          <S.OrganizationListItemName>{label}</S.OrganizationListItemName>
        </S.OrganizationListItemContent>
      ) : (
        <S.MenuListText>{label}</S.MenuListText>
      )}
      {isActive &&
        (isLoadingOrganizationSubscriptions ? <LoadingIcon /> : <Tick />)}
    </S.ListItem>
  );
};

type OrganizationMobileMenuSelectOrganizationProps = {
  isOpen: boolean;
  setIsOpen: (boolean) => void;
  setIsOrganizationSelectionMenu: (boolean) => void;
};

const OrganizationMobileMenuSelectOrganization = ({
  isOpen,
  setIsOpen,
  setIsOrganizationSelectionMenu
}: OrganizationMobileMenuSelectOrganizationProps) => {
  const intl = useIntl();
  const { organizationId } = useParams<{ organizationId: string }>();
  const navigate = useNavigate();

  const organizationListRef = useRef<HTMLUListElement>(null);
  const [searchInputValue, setSearchInputValue] = useState<string>();
  const shouldShowAllProjectsOption = !useAppSelector(
    selectFeatureDisableAllProjectsEnabled
  );

  const createOrganizationEnabled = useAppSelector(
    selectFeatureCreateOrganizationEnabled
  );
  const organizations = useAppSelector(organizationsMultiVendorOrgsSelector);

  const formattedOrganizationList = useMemo(
    () =>
      getFormattedOrganizations({
        organizationsList: organizations,
        searchInputValue
      }),
    [organizations, searchInputValue]
  );

  const numberOfOrgsBeforeOverflow = shouldShowAllProjectsOption
    ? NUMBER_OF_ORGS_BEFORE_OVERFLOW_ALL_PROJECTS
    : NUMBER_OF_ORGS_BEFORE_OVERFLOW;
  const shouldShowSearchInput =
    organizations.length >= numberOfOrgsBeforeOverflow;
  const shouldShowShadow =
    formattedOrganizationList &&
    formattedOrganizationList?.length >= numberOfOrgsBeforeOverflow;
  const activeOrganizationIndex = formattedOrganizationList?.findIndex(
    organization => organizationId === organization?.name
  );
  const [isOpenTracker, setIsOpenTracker] = useState(isOpen);

  const [createOrganizationUrl] = useUrls("newOrganization", {
    organizationId: organizationId ?? ""
  });

  useEffect(() => {
    if (isOpen && !isOpenTracker && activeOrganizationIndex) {
      organizationListRef?.current?.scrollTo(0, 24 * activeOrganizationIndex);
    }
    setIsOpenTracker(isOpen);
  }, [isOpen, isOpenTracker, activeOrganizationIndex]);

  const onOrganizationClick = (organization: Organization) => {
    setIsOpen(false);
    if (!consoleConfig.CUSTOM_ORGANIZATION_VENDORS)
      return navigate(`/${organization.name}`);

    const vendorURL =
      consoleConfig.CUSTOM_ORGANIZATION_VENDORS[organization.vendor];
    if (vendorURL && vendorURL !== location.origin)
      location.href = `${vendorURL}/${organization.name}`;
    else navigate(`/${organization.name}`);
  };

  const hasOrganizationsFromMultipleVendors = useMemo(() => {
    if (!consoleConfig.CUSTOM_ORGANIZATION_VENDORS) return false;
    const currentVendor = Object.entries(
      consoleConfig.CUSTOM_ORGANIZATION_VENDORS
    ).find(([, value]) => value === "")?.[0];
    return organizations.some(
      organization => organization.vendor !== currentVendor
    );
  }, [organizations]);

  if (!isOpen) return null;

  const setSearch = (value: string) => setSearchInputValue(value);

  return (
    <S.FloatingOrganizationContainer>
      <HeaderMenuMobile
        onClickBack={() => setIsOrganizationSelectionMenu(false)}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
      <S.SelectOrganizationSection>
        {shouldShowSearchInput && (
          <S.SearchInput
            id="organizations-search"
            placeholder={
              searchInputValue
                ? intl.formatMessage({ id: "organizations.search" })
                : ""
            }
            onChange={setSearch}
            value={searchInputValue}
          />
        )}
        <S.OrganizationListContainer
          ref={organizationListRef}
          isSearchBoxVisible={shouldShowSearchInput}
          shouldOverscroll={shouldShowShadow}
        >
          {shouldShowAllProjectsOption && (
            <S.ListItem
              isActive={!organizationId}
              onClick={() => navigate("/")}
            >
              <S.MenuListText>
                {intl.formatMessage({ id: "all_projects" })}
              </S.MenuListText>
              {!organizationId && <Tick />}
            </S.ListItem>
          )}
          <Label>{intl.formatMessage({ id: "select_organization" })}</Label>
          {formattedOrganizationList?.map(organization => (
            <OrganizationListItem
              key={organization?.label}
              isActive={organizationId === organization?.name}
              onClick={() => onOrganizationClick(organization)}
              label={organization?.label}
              vendor={organization?.vendor}
              showVendor={hasOrganizationsFromMultipleVendors}
            />
          ))}
        </S.OrganizationListContainer>
        {createOrganizationEnabled && (
          <S.NewOrganizationButtonContainer shouldShowShadow={shouldShowShadow}>
            <AddButton
              variant="link"
              to={createOrganizationUrl}
              analyticId="organization.new"
            >
              {intl.formatMessage({ id: "organization.new" })}
            </AddButton>
          </S.NewOrganizationButtonContainer>
        )}
      </S.SelectOrganizationSection>
    </S.FloatingOrganizationContainer>
  );
};

export default OrganizationMobileMenuSelectOrganization;
