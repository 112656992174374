import React, { useMemo, useState, useEffect } from "react";

import { useIntl } from "react-intl";

import DateRangePicker from "Components/DateRangePicker/DateRangePicker";
import * as Accordion from "ds/Accordion";
import Checkbox from "ds/Checkbox/Checkbox";
import Label from "ds/Label";
import Modal from "ds/Modal";
import Select from "ds/Select";
import { BodyText, Title } from "ds/Typography";
import { setCurrentTheme } from "Libs/themes";
import { capitalize, objectKeys } from "Libs/utils";
import { selectCurrentUserUsername } from "Reducers/app/selectors";
import { resetFeatureFlags } from "Reducers/featureFlags/featureFlags.thunks";
import { useFeatureFlagToggle } from "Reducers/featureFlags/useFeatureFlagToggle";
import { profileColorScheme } from "Reducers/profile";
import {
  setTestTheme,
  setTestTrialModes,
  setTestSystemStatus,
  isActiveSelector,
  testModeSelector
} from "Reducers/testMode";
import { SYSTEM_STATUS } from "src/tickets/pages/list/components/SystemStatusBanner/SystemStatusBanner";
import { useAppDispatch, useAppSelector } from "Store/hooks";

import * as S from "./TestingModal.styles";

type TestingModalPropType = {
  isOpen: boolean;
  onClose: () => void;
};

const TestingModal = ({ isOpen, onClose }: TestingModalPropType) => {
  const dispatch = useAppDispatch();
  const formatMessage = useIntl().formatMessage;
  const selected = useAppSelector(testModeSelector);
  const isActive = useAppSelector(state => isActiveSelector(state));
  const [theme, setTheme] = useState(selected.theme);
  const [trial, setTrial] = useState({ ...selected.trial });
  const [accountSecurityRouteEnabled, setAccountSecurityRouteEnabled] =
    useFeatureFlagToggle("ENABLE_ACCOUNT_SECURITY_ROUTE");
  const [automatedBackupsEnabled, setAutomatedBackupsEnabled] =
    useFeatureFlagToggle("ENABLE_AUTOMATED_BACKUPS");
  const [connectedAccountsRouteEnabled, setConnectedAccountsRouteEnabled] =
    useFeatureFlagToggle("ENABLE_CONNECTED_ACCOUNTS_ROUTE");
  const [unbrandedIllustrations, setUnbrandedIllustrations] =
    useFeatureFlagToggle("ENABLE_UNBRANDED_ILLUSTRATIONS");
  const [allProjectsDisabled, setAllProjectsDisabled] = useFeatureFlagToggle(
    "DISABLE_ALL_PROJECTS"
  );
  const [userManagementEnabled, setUserManagementEnabled] =
    useFeatureFlagToggle("ENABLE_USER_MANAGEMENT");
  const [
    addProjectUserPricingAgreementEnabled,
    setAddProjectUserPricingAgreementEnabled
  ] = useFeatureFlagToggle("ENABLE_ADD_PROJECT_USER_PRICING_AGREEMENT");
  const [accountPagesEnabled, setAccountPagesEnabled] = useFeatureFlagToggle(
    "ENABLE_ACCOUNT_PAGES"
  );
  const [blackfireCardEnabled, setBlackfireCardEnabled] = useFeatureFlagToggle(
    "ENABLE_BLACKFIRE_CARD"
  );
  const [createProjectEnabled, setCreateProjectEnabled] = useFeatureFlagToggle(
    "ENABLE_CREATE_PROJECT"
  );
  const [deploymentOverlayEnabled, setDeploymentOverlayEnabled] =
    useFeatureFlagToggle("ENABLE_DEPLOYMENT_OVERLAY");

  const [environmentEmailSettingEnabled, setEnvironmentEmailSettingEnabled] =
    useFeatureFlagToggle("ENABLE_ENVIRONMENT_EMAIL_SETTING");

  const [organization, setOrganization] = useFeatureFlagToggle(
    "ENABLE_ORGANIZATION"
  );

  const [createOrganization, setCreateOrganization] = useFeatureFlagToggle(
    "ENABLE_CREATE_ORGANIZATION"
  );

  const [sourceRepositoryTooltip, setSourceRepositoryTooltip] =
    useFeatureFlagToggle("ENABLE_SOURCE_REPOSITORY_TOOLTIP");

  const [environmentSocketEnabled, setEnvironmentSocketEnabled] =
    useFeatureFlagToggle("ENABLE_ENVIRONMENT_SOCKET");

  const [continuousProfiling, setEnableContinuousProfiling] =
    useFeatureFlagToggle("ENABLE_PROFILING");

  const [billing, setBilling] = useFeatureFlagToggle("ENABLE_BILLING");
  const [vouchers, setVouchers] = useFeatureFlagToggle("ENABLE_VOUCHERS");
  const [editPlan, setEditPlan] = useFeatureFlagToggle("ENABLE_EDIT_PLAN");
  const [
    observabilitySuiteIntegrationsEnabled,
    setObservabilitySuiteIntegrationsEnabled
  ] = useFeatureFlagToggle("ENABLE_OBSERVABILITY_SUITE_INTEGRATIONS");
  const [sourceOperation, setSourceOperation] = useFeatureFlagToggle(
    "ENABLE_SOURCE_OPERATION"
  );
  const [serviceTree, setServiceTree] = useFeatureFlagToggle(
    "ENABLE_SERVICE_TREE"
  );
  const [announcements, setAnnouncements] = useFeatureFlagToggle(
    "ENABLE_ANNOUNCEMENTS"
  );
  const [projectSetupWizardEnabled, setProjectSetupWizard] =
    useFeatureFlagToggle("ENABLE_PROJECT_SETUP_WIZARD");
  const [resetProfilePasswordEnabled, setResetProfilePasswordEnabled] =
    useFeatureFlagToggle("ENABLE_RESET_PROFILE_PASSWORD");
  const [usageBasedBilling, setUsageBasedBilling] = useFeatureFlagToggle(
    "ENABLE_USAGE_BASED_BILLING_PAGES"
  );
  const [organizationFirstOnboarding, setOrganizationFirstOnboarding] =
    useFeatureFlagToggle("ENABLE_ORGANIZATION_FIRST_ONBOARDING");
  const [migrationFlow, setMigrationFlow] = useFeatureFlagToggle(
    "ENABLE_MIGRATION_FLOW"
  );

  const [customLeftPanel, setCustomLeftPanel] = useFeatureFlagToggle(
    "ENABLE_CUSTOM_LEFT_PANEL"
  );

  const [resourceAllocation, setResourceAllocation] = useFeatureFlagToggle(
    "ENABLE_TOTAL_RESOURCE_ALLOCATION_METRICS"
  );

  const [systemStatus, setSystemStatus] = useState(selected.systemStatus);

  const [range, setRange] = useState<{ start?: Date; end?: Date }>({
    start: undefined,
    end: undefined
  });

  useEffect(() => {
    setRange({
      start: trial.startDate as Date,
      end: trial.expirationDate as Date
    });
  }, [trial]);

  const themeOptions = [
    { value: "", label: "Default" },
    { value: "light", label: "Light" },
    { value: "dark", label: "Dark" },
    { value: "contrast", label: "Contrast" }
  ];
  const trialStatusOptions = [
    { value: "", label: "Default" },
    { value: "active", label: "Active" },
    { value: "payment_missing", label: "Payment method missing" }
  ];
  const systemStatusOptions = useMemo(() => {
    const options = objectKeys(SYSTEM_STATUS).map(key => ({
      value: SYSTEM_STATUS[key],
      label: capitalize(SYSTEM_STATUS[key])
    }));
    options.unshift({ value: "", label: "Default" });
    return options;
  }, []);

  const username = useAppSelector(selectCurrentUserUsername);

  const themeFromProfile = useAppSelector(state =>
    profileColorScheme(state, username)
  );

  const onDateChange = ({
    start,
    end
  }: {
    start?: string | Date;
    end?: string | Date;
  }) => {
    setTrial({
      ...trial,
      startDate: start,
      expirationDate: end
    });
  };

  const isChanged =
    theme !== "" ||
    trial.startDate !== undefined ||
    trial.expirationDate !== undefined ||
    trial.status !== "" ||
    systemStatus !== "";
  const applySettings = () => {
    setCurrentTheme(theme === "" ? themeFromProfile : theme);
    dispatch(setTestTheme(theme));
    dispatch(setTestTrialModes(trial));
    dispatch(setTestSystemStatus(systemStatus));
    onClose();
  };
  const clearAll = () => {
    setTheme("");
    setSystemStatus("");
    setTrial({ startDate: undefined, expirationDate: undefined, status: "" });
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={open => open && onClose()}
      size="large"
    >
      <Title tag="h3">
        {formatMessage({
          id: "test_mode.title"
        })}
        {isActive && (
          <S.TitleActiveTag variant="yellow">
            {formatMessage({
              id: "test_mode.active_label"
            })}
          </S.TitleActiveTag>
        )}
      </Title>
      <BodyText>
        {formatMessage({
          id: "test_mode.description",
          defaultMessage:
            "Apply test settings to change the appearance or system status of console. These will remain until cleared manually."
        })}
      </BodyText>
      <S.AccordionWrapper>
        <Accordion.Root
          type="multiple"
          defaultValue={["theme", "featureFlags", "trialModes", "systemStatus"]}
        >
          <Accordion.Item value="theme">
            <Accordion.SectionHeader
              onClear={theme !== "" ? () => setTheme("") : undefined}
            >
              {formatMessage({
                id: "test_mode.theming.title",
                defaultMessage: "Theming"
              })}
            </Accordion.SectionHeader>
            <Accordion.Content>
              <S.FormWrapper>
                <S.SelectWrapper>
                  <Label htmlFor="test-mode-theme">
                    {formatMessage({ id: "test_mode.theming.select_mode" })}
                  </Label>
                  <Select
                    options={themeOptions}
                    name="test-mode-theme"
                    id="test-mode-theme"
                    isMulti={false}
                    onChange={newValue => setTheme(newValue?.value || "")}
                    value={themeOptions.find(o => o.value === theme)}
                    defaultValue={themeOptions.find(o => o.value === "")}
                  />
                </S.SelectWrapper>
              </S.FormWrapper>
            </Accordion.Content>
          </Accordion.Item>
          <Accordion.Item value="featureFlags">
            <Accordion.SectionHeader
              onClear={() => {
                dispatch(resetFeatureFlags());
              }}
            >
              Feature Flags
            </Accordion.SectionHeader>
            <BodyText style={{ margin: "-10px 0 20px" }}>
              Clicking on clear will remove your local overrides and go back to
              the flags from the environment
            </BodyText>
            <Accordion.Content>
              <S.FormWrapper>
                <S.FeatureFlags>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={accountPagesEnabled}
                      onCheckedChanged={() =>
                        setAccountPagesEnabled(!accountPagesEnabled)
                      }
                      label="Account pages"
                      forId="accountPages"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={announcements}
                      onCheckedChanged={() => setAnnouncements(!announcements)}
                      label="Announcements (Needs reload)"
                      forId="announcements"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={unbrandedIllustrations}
                      onCheckedChanged={() =>
                        setUnbrandedIllustrations(!unbrandedIllustrations)
                      }
                      label="Unbranded illustrations *"
                      forId="unbrandedIllustrations"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={organization}
                      onCheckedChanged={() => setOrganization(!organization)}
                      label="Organization"
                      forId="organization"
                    />
                  </S.CheckboxWrapper>

                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={createOrganization}
                      onCheckedChanged={() =>
                        setCreateOrganization(!createOrganization)
                      }
                      label="Create Organization"
                      forId="createOrganization"
                    />
                  </S.CheckboxWrapper>

                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={createOrganization}
                      onCheckedChanged={() =>
                        setSourceRepositoryTooltip(!sourceRepositoryTooltip)
                      }
                      label="Source Repository Tooltip"
                      forId="sourceRepositoryTooltip"
                    />
                  </S.CheckboxWrapper>

                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={environmentSocketEnabled}
                      onCheckedChanged={() =>
                        setEnvironmentSocketEnabled(!environmentSocketEnabled)
                      }
                      label="Environment Socket"
                      forId="environmentSocketEnabled"
                    />
                  </S.CheckboxWrapper>

                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={billing}
                      onCheckedChanged={() => setBilling(!billing)}
                      label="Billing"
                      forId="billing"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={vouchers}
                      onCheckedChanged={() => setVouchers(!vouchers)}
                      label="Voucher"
                      forId="vouchers"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={userManagementEnabled}
                      onCheckedChanged={() =>
                        setUserManagementEnabled(!userManagementEnabled)
                      }
                      label="User Management"
                      forId="userManagement"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={allProjectsDisabled}
                      onCheckedChanged={() =>
                        setAllProjectsDisabled(!allProjectsDisabled)
                      }
                      label="All projects disabled"
                      forId="allprojects"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={blackfireCardEnabled}
                      onCheckedChanged={() =>
                        setBlackfireCardEnabled(!blackfireCardEnabled)
                      }
                      label="Blackfire Card"
                      forId="blackfireCard"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={editPlan}
                      onCheckedChanged={() => setEditPlan(!editPlan)}
                      label="Edit Plan"
                      forId="editPlan"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={sourceOperation}
                      onCheckedChanged={() =>
                        setSourceOperation(!sourceOperation)
                      }
                      label="Source Operations"
                      forId="sourceOperation"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={serviceTree}
                      onCheckedChanged={() => setServiceTree(!serviceTree)}
                      label="Service Tree"
                      forId="serviceTree"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={projectSetupWizardEnabled}
                      onCheckedChanged={() =>
                        setProjectSetupWizard(!projectSetupWizardEnabled)
                      }
                      label="Project Wizard"
                      forId="projectWizard"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={automatedBackupsEnabled}
                      onCheckedChanged={() =>
                        setAutomatedBackupsEnabled(!automatedBackupsEnabled)
                      }
                      label="Automated Backups"
                      forId="automatedBackups"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={connectedAccountsRouteEnabled}
                      onCheckedChanged={() =>
                        setConnectedAccountsRouteEnabled(
                          !connectedAccountsRouteEnabled
                        )
                      }
                      label="Connected Accounts"
                      forId="connectedAccounts"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={accountSecurityRouteEnabled}
                      onCheckedChanged={() =>
                        setAccountSecurityRouteEnabled(
                          !accountSecurityRouteEnabled
                        )
                      }
                      label="Account Security"
                      forId="accountSecurity"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={createProjectEnabled}
                      onCheckedChanged={() =>
                        setCreateProjectEnabled(!createProjectEnabled)
                      }
                      label="Create Project"
                      forId="createProject"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={deploymentOverlayEnabled}
                      onCheckedChanged={() =>
                        setDeploymentOverlayEnabled(!deploymentOverlayEnabled)
                      }
                      label="Deployment Overlay"
                      forId="deploymentOverlay"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={environmentEmailSettingEnabled}
                      onCheckedChanged={() =>
                        setEnvironmentEmailSettingEnabled(
                          !environmentEmailSettingEnabled
                        )
                      }
                      label="Environment Email"
                      forId="environmentEmail"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={addProjectUserPricingAgreementEnabled}
                      onCheckedChanged={() =>
                        setAddProjectUserPricingAgreementEnabled(
                          !addProjectUserPricingAgreementEnabled
                        )
                      }
                      label="Add Project User Pricing Agreement"
                      forId="addProjectUserPricingAgreement"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={observabilitySuiteIntegrationsEnabled}
                      onCheckedChanged={() =>
                        setObservabilitySuiteIntegrationsEnabled(
                          !observabilitySuiteIntegrationsEnabled
                        )
                      }
                      label="Observability Suite Integrations"
                      forId="observabilitySuiteIntegrations"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={resetProfilePasswordEnabled}
                      onCheckedChanged={() =>
                        setResetProfilePasswordEnabled(
                          !resetProfilePasswordEnabled
                        )
                      }
                      label="Reset Profile Password"
                      forId="resetProfilePassword"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={usageBasedBilling}
                      onCheckedChanged={() =>
                        setUsageBasedBilling(!usageBasedBilling)
                      }
                      label="Usage Based Billing"
                      forId="usageBaseBilling"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={organizationFirstOnboarding}
                      onCheckedChanged={() =>
                        setOrganizationFirstOnboarding(
                          !organizationFirstOnboarding
                        )
                      }
                      label="Organization First Onboarding"
                      forId="organizationFirstOnboarding"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={migrationFlow}
                      onCheckedChanged={() => setMigrationFlow(!migrationFlow)}
                      label="Migration Flow"
                      forId="migrationFlow"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={customLeftPanel}
                      onCheckedChanged={() =>
                        setCustomLeftPanel(!customLeftPanel)
                      }
                      label="Custom Left Panel"
                      forId="customLeftPanel"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={resourceAllocation}
                      onCheckedChanged={() =>
                        setResourceAllocation(!resourceAllocation)
                      }
                      label="Total Resource Allocation Metrics"
                      forId="resourceAllocationMetrics"
                    />
                  </S.CheckboxWrapper>
                  <S.CheckboxWrapper>
                    <Checkbox
                      checked={continuousProfiling}
                      onCheckedChanged={() =>
                        setEnableContinuousProfiling(!continuousProfiling)
                      }
                      label="Countinuous Profiling"
                      forId="continuousProfiling"
                    />
                  </S.CheckboxWrapper>
                </S.FeatureFlags>
              </S.FormWrapper>
              <BodyText>
                * some error pages are not on redux so this feature is not
                disabled for these
              </BodyText>
            </Accordion.Content>
          </Accordion.Item>
          <Accordion.Item value="trialModes">
            <Accordion.SectionHeader
              onClear={
                (trial.startDate !== undefined ||
                  trial.expirationDate !== undefined ||
                  trial.status) !== ""
                  ? () => {
                      setTrial({
                        startDate: undefined,
                        expirationDate: undefined,
                        status: ""
                      });
                    }
                  : undefined
              }
            >
              {formatMessage({
                id: "test_mode.trial_modes.title",
                defaultMessage: "Trial modes"
              })}
            </Accordion.SectionHeader>
            <Accordion.Content>
              <S.FormWrapper>
                <S.SelectWrapper>
                  <DateRangePicker
                    onChange={onDateChange}
                    dualView={false}
                    range={range}
                    disableTimePicker={true}
                    disableButtonAction={true}
                  />
                </S.SelectWrapper>
                <S.SelectWrapper>
                  <Label htmlFor="test-mode-trial-status">
                    {formatMessage({
                      id: "test_mode.trial_modes.select_status"
                    })}
                  </Label>
                  <Select
                    options={trialStatusOptions}
                    name="test-mode-trial-status"
                    id="test-mode-trial-status"
                    isMulti={false}
                    onChange={newValue =>
                      setTrial(prevState => ({
                        ...prevState,
                        status: newValue?.value || ""
                      }))
                    }
                    value={trialStatusOptions.find(
                      o => o.value === trial.status
                    )}
                    defaultValue={trialStatusOptions.find(o => o.value === "")}
                  />
                </S.SelectWrapper>
              </S.FormWrapper>
            </Accordion.Content>
          </Accordion.Item>
          <Accordion.Item value="systemStatus">
            <Accordion.SectionHeader
              onClear={
                systemStatus !== "" ? () => setSystemStatus("") : undefined
              }
            >
              {formatMessage({
                id: "test_mode.system_status.title",
                defaultMessage: "System status"
              })}
            </Accordion.SectionHeader>
            <Accordion.Content>
              <S.FormWrapper>
                <S.SelectWrapper>
                  <Label htmlFor="test-mode-system-status">
                    {formatMessage({
                      id: "test_mode.system_status.select_status"
                    })}
                  </Label>
                  <Select
                    options={systemStatusOptions}
                    name="test-mode-system-status"
                    id="test-mode-system-status"
                    isMulti={false}
                    onChange={newValue =>
                      setSystemStatus(newValue?.value || "")
                    }
                    value={systemStatusOptions.find(
                      o => o.value === systemStatus
                    )}
                    defaultValue={systemStatusOptions.find(o => o.value === "")}
                  />
                </S.SelectWrapper>
              </S.FormWrapper>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
        <S.Divider />
      </S.AccordionWrapper>
      <BodyText>
        <S.InfoIcon />
        {formatMessage({ id: "test_mode.page_refresh" })}
      </BodyText>
      <S.TestModalButtonWrapper>
        <S.TestingButton
          analyticId="clear_all"
          variant="outline"
          aria-label={formatMessage({ id: "clear_all" })}
          onClick={clearAll}
          disabled={!isChanged}
        >
          {capitalize(formatMessage({ id: "clear_all" }))}
        </S.TestingButton>
        <S.TestingButton
          variant="secondary"
          aria-label={formatMessage({ id: "cancel" })}
          onClick={onClose}
          analyticId="cancel"
        >
          {capitalize(formatMessage({ id: "cancel" }))}
        </S.TestingButton>
        <S.TestingButton
          data-testid="test-save-button"
          aria-label={capitalize(formatMessage({ id: "save" }))}
          onClick={applySettings}
          analyticId="save"
        >
          {capitalize(formatMessage({ id: "save" }))}
        </S.TestingButton>
      </S.TestModalButtonWrapper>
    </Modal>
  );
};

export default TestingModal;
