import styled from "styled-components";

import { ButtonWrapper } from "ds/Button";
import * as Typography from "ds/Typography";

export const Help = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: var(--section-slate-color, var(--slate));

  a,
  button {
    font-weight: 600;
  }
`;

export const Wrapper = styled.section`
  ${Typography.BodyText} {
    margin-bottom: 24px;
  }

  ${Help} {
    margin-top: 24px;
  }

  ${ButtonWrapper} {
    justify-content: end;
  }
`;

export const LoadingWrapper = styled.div`
  margin: 0 auto;
`;
