import styled from "styled-components";

import { Link as LinkDs } from "ds/Button";
import * as Typography from "ds/Typography";

export const Wrapper = styled.div<{ bg: string }>`
  display: flex;
  margin-bottom: 24px;
  padding: 8px 16px;
  background-color: ${props => props.bg};
  border-radius: var(--2px);
`;

export const IconWrapper = styled.div`
  display: flex;
  margin-right: 14px;
`;

export const Link = styled(LinkDs)`
  margin-left: auto;
`;

export const StatusText = styled(Typography.BodyText)`
  color: var(--theme-neutral-900);
`;
