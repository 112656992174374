import React, { useState, useMemo } from "react";

import { useIntl } from "react-intl";

import useDecodedParams from "Hooks/useDecodedParams";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";
import { isEnvInactiveOrDeleting } from "Libs/utils";
import { environmentsAsArraySelector } from "Reducers/environment";
import {
  EnvironmentTreeData,
  getAllEnvironments,
  getTreeData
} from "src/common/components/EnvironmentTree/utils";

import MenuLinkItem from "../MenuLinkItem";

import * as S from "./Menu.styles";

const hasActiveChildren = env => {
  if (!env.children || env.children.length === 0) {
    return false;
  }
  if (env.children.some(child => child.status === "active")) {
    return true;
  }
  return hasActiveChildren(env.children);
};

const Menu = () => {
  const intl = useIntl();
  const { environmentId, organizationId } = useDecodedParams<{
    environmentId: string;
    organizationId: string;
  }>();
  const [titleFilter, setTitleFilter] = useState("");

  const environments = useSelectorWithUrlParams(environmentsAsArraySelector);

  const tree = useMemo(() => {
    if (!environments) return [];
    const rootEnv = environments.filter(environment => !environment.parent);
    return getTreeData(rootEnv, environments);
  }, [environments]);

  const list = useMemo(() => getAllEnvironments(tree), [tree]);

  const environmentFilter = env => {
    if (isEnvInactiveOrDeleting(env.status) && !hasActiveChildren(env)) {
      return false;
    }
    if (!env.title) return true;

    return env.title.toUpperCase().includes(titleFilter.toUpperCase());
  };

  if (!list?.length) {
    return null;
  }

  let environmentsList = tree;
  if (titleFilter !== "") {
    environmentsList = list.filter(environmentFilter).map(l => {
      return { ...l, children: [] };
    });
  }
  environmentsList = environmentsList.sort((a, b) => {
    if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
    return 0;
  });

  const countEnv = (arr: EnvironmentTreeData[]) =>
    arr.reduce((acc, { children = [] }) => {
      acc++;
      if (children.length) acc += countEnv(children);
      return acc;
    }, 0);
  const moreThanTen = countEnv(environmentsList) > 10;

  return (
    <>
      <S.SearchInput
        customWidth="100%"
        placeholder={intl.formatMessage({ id: "environment.search" })}
        onChange={(value: string) => setTitleFilter(value)}
        value={titleFilter}
        id="environment-search"
        showClose={false}
        autoFocus
      />
      <S.Environments
        className="environments-links"
        role="menu"
        aria-label="environments"
      >
        {list.length === 0 ? (
          <S.Empty>
            {intl.formatMessage({
              id: "environmentTree.menu.empty",
              defaultMessage: "No results."
            })}
          </S.Empty>
        ) : (
          <ListWrapper scrollable={moreThanTen}>
            {environmentsList.map(node => {
              return (
                <MenuLinkItem
                  id="environment-branch"
                  node={node}
                  organizationId={organizationId}
                  currentEnvironment={environmentId}
                  key={node.environmentId}
                  searchValue={titleFilter}
                  filter={environmentFilter}
                />
              );
            })}
          </ListWrapper>
        )}
      </S.Environments>
    </>
  );
};

type ListWrapperProps = {
  children: React.ReactNode;
  scrollable: boolean;
};

const ListWrapper = ({ children, scrollable }: ListWrapperProps) =>
  scrollable ? (
    <S.Scrollbox>{children}</S.Scrollbox>
  ) : (
    <S.NoScroll>{children}</S.NoScroll>
  );

export default Menu;
