import { createSlice } from "@reduxjs/toolkit";

import { setDeep } from "Libs/objectAccess";
import { normalize } from "Libs/utils";

import {
  addVariable,
  getVariables,
  updateVariable,
  deleteVariable
} from "./thunks";

import type { Variable } from "platformsh-client";

type VariableData = {
  [organizationId: string]:
    | {
        [projectId: string]:
          | {
              [environmentId: string]:
                | {
                    [variableId: string]: Variable;
                  }
                | undefined;
            }
          | undefined;
      }
    | undefined;
};

export type VariableSettingsState = Readonly<{
  data: VariableData;
  loading: boolean;
  status?: "added" | "deleted" | "idle" | "pending" | "rejected" | "updated";
  errors?: string;
}>;

const initialState: VariableSettingsState = { data: {}, loading: true };

const variables = createSlice({
  name: "app/project/environment/variable",
  initialState,
  reducers: {
    clearEnvironmentVariableError(state) {
      state.errors = undefined;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getVariables.pending, state => {
        state.loading = true;
      })
      .addCase(getVariables.fulfilled, (state, action) => {
        const { environmentId, organizationId, projectId } = action.meta.arg;
        if (action.payload) {
          setDeep(
            state,
            ["data", organizationId, projectId, environmentId],
            normalize(action.payload, "id")
          );
        }
        state.loading = false;
        state.status = "idle";
      })
      .addCase(getVariables.rejected, (state, action) => {
        state.errors = action.payload?.error;
        state.loading = false;
        state.status = "rejected";
      })
      .addCase(addVariable.pending, state => {
        state.status = "pending";
        state.errors = undefined;
        state.loading = true;
      })
      .addCase(addVariable.fulfilled, (state, action) => {
        const { environment, organizationId, projectId } = action.meta.arg;
        if (action.payload) {
          setDeep(
            state,
            [
              "data",
              organizationId,
              projectId,
              environment.id,
              action.payload.id
            ],
            action.payload
          );
        }
        state.status = "added";
        state.loading = false;
        state.errors = undefined;
      })
      .addCase(addVariable.rejected, (state, action) => {
        state.errors = action.payload?.error;
        state.status = "rejected";
        state.loading = false;
      })
      .addCase(updateVariable.pending, state => {
        state.status = "pending";
        state.errors = undefined;
        state.loading = true;
      })
      .addCase(updateVariable.fulfilled, (state, action) => {
        const { environmentId, organizationId, projectId } = action.meta.arg;
        if (action.payload) {
          setDeep(
            state,
            [
              "data",
              organizationId,
              projectId,
              environmentId,
              action.payload.id
            ],
            action.payload
          );
          state.status = "updated";
          state.loading = false;
        }
      })
      .addCase(updateVariable.rejected, (state, action) => {
        state.errors = action.payload?.error;
        state.status = "rejected";
        state.loading = false;
      })
      .addCase(deleteVariable.pending, state => {
        state.status = "pending";
        state.errors = undefined;
        state.loading = true;
      })
      .addCase(deleteVariable.fulfilled, (state, action) => {
        const { environmentId, organizationId, projectId } = action.meta.arg;
        delete state.data[organizationId]![projectId]![environmentId]![
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          action.payload.id
        ];
        state.status = "deleted";
        state.loading = false;
      })
      .addCase(deleteVariable.rejected, (state, action) => {
        state.errors = action.payload?.error;
        state.status = "rejected";
        state.loading = false;
      });
  }
});

export default variables.reducer;

export const { clearEnvironmentVariableError } = variables.actions;
